/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/button-has-type */
import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  PauseIcon,
  PlayIcon,
  ForwardIcon,
  BackwardIcon,
} from "@heroicons/react/24/outline";

const Controls = ({ audioRef, progressBarRef, duration, setTimeProgress }) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlayPause = () => {
    setIsPlaying((prev) => !prev);
  };

  const playAnimationRef = useRef<number | undefined>(undefined);

  const repeat = useCallback(() => {
    const { currentTime } = audioRef.current;
    setTimeProgress(currentTime);
    progressBarRef.current.value = currentTime;
    progressBarRef.current.style.setProperty(
      "--range-progress",
      `${(progressBarRef.current.value / duration) * 100}%`
    );

    playAnimationRef.current = requestAnimationFrame(repeat);
  }, [audioRef, duration, progressBarRef, setTimeProgress]);

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
    playAnimationRef.current = requestAnimationFrame(repeat);
  }, [isPlaying, audioRef, repeat]);

  const skipForward = () => {
    audioRef.current.currentTime += 15;
  };

  const skipBackward = () => {
    audioRef.current.currentTime -= 15;
  };

  return (
    <div className="flex justify-center w-full -mt-3">
      <div className="flex flex-row justify-center m-auto text-white space-x-6">
        <button type="button" onClick={skipBackward}>
          {" "}
          <BackwardIcon className="m-auto text-white h-9 w-9" />
        </button>
        <button type="button" onClick={togglePlayPause}>
          {isPlaying ? (
            <PauseIcon className="m-auto text-white h-9 w-9" />
          ) : (
            <PlayIcon className="m-auto text-white h-9 w-9" />
          )}
        </button>
        <button type="button" onClick={skipForward}>
          {" "}
          <ForwardIcon className="m-auto text-white h-9 w-9" />
        </button>
      </div>
    </div>
  );
};

export default Controls;
