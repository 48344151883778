import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import QRCode from "react-qr-code";
import * as backend from "bitmask-core";
import { CARBONADO, DISABLE_LN, LNDHUBX } from "bitmask-core/constants";
import { createInvoice } from "bitmask-core/rgb";

import { Location } from "src/types";
import RoundedButton from "src/Components/Buttons/RoundedButton";
import PageWrapper from "src/Components/Layout/Wrappers/PageWrapper";
import PageWrapper2 from "src/Components/Layout/Wrappers/PageWrapper2";
import CopiedToClipboard from "src/Components/Modals/CopiedToClipboard";
import ErrorModal from "src/Components/Modals/Error";
import Modal from "src/Components/Modals/Modal";
import FundAssets from "src/Components/Modals/Children/FundAssets";
import TextField from "src/Components/Inputs/TextField";
import { addBalance, lndhubError } from "src/Hooks/util";
import CopyButton from "src/Components/Buttons/CopyButton";
import { ifaceFungibles, ifaceUdas } from "src/constants";
import Bitcoin from "src/Components/Icons/Bitcoin";
import LightningNetwork from "src/Components/Icons/LightningNetwork";
import CheckCircle from "src/Components/Icons/CheckCircle";

const generateInvoice = async (vault, contractId, amount, type, assetUtxos) => {
  let currentUtxo = "";
  let invoiceAmount = {};
  switch (type) {
    case ifaceUdas: {
      const allocation = amount;
      currentUtxo = assetUtxos.currentUtxoUdas;
      invoiceAmount = { allocation: `1@${allocation}` };
      break;
    }
    default:
      currentUtxo = assetUtxos.currentUtxoTokens;
      invoiceAmount = {
        value: `${amount}`,
      };
  }

  const { invoice } = await createInvoice(vault.private.nostrPrv, {
    contractId,
    iface: type === "UDA" ? ifaceUdas : ifaceFungibles,
    amount: invoiceAmount,
    seal: {
      utxo: currentUtxo,
    },
  });
  return invoice;
};

const Receive = () => {
  const navigate = useNavigate();
  const location = useLocation() as Location;
  const {
    walletData,
    vault,
    type,
    locationHistory,
    lnCredentials,
    tokensWalletFunded,
    tokensWalletAddress,
    assetUtxos,
    udasWalletAddress,
    hash,
    contractId: contract,
  } = location.state;
  const [option, setOption] = useState(type || "");
  const [lnInvoice, setLnInvoice] = useState({
    description: "",
    amount: 0,
    invoice: "",
  });
  // eslint-disable-next-line no-unused-vars
  const [rgbInvoice, setRgbInvoice] = useState("");
  const [lnTxPending, setLnTxPending] = useState(true);
  const [open, setOpen] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [openFundAssetsModal, setOpenFundAssetsModal] = useState(false);
  const [contractId, setContractId] = useState(contract || "");
  const [amount, setAmount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    title: "",
    message: "",
  });
  const network = window.localStorage.getItem("network");

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (lnInvoice.invoice) {
      const interval = setInterval(async () => {
        // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
        const paymentHash = require("light-bolt11-decoder").decode(
          lnInvoice.invoice
        ).sections[5].value;
        const check = await backend.lightning.checkPayment(paymentHash);

        if (check) {
          const syncNonce = JSON.parse(
            localStorage.getItem("syncNonce") || "0"
          );
          localStorage.setItem("syncNonce", JSON.stringify(syncNonce + 1));
        }

        setLnTxPending(!check);
        if (check) clearInterval(interval);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [lnInvoice.invoice]);

  if (option === "") {
    return (
      <PageWrapper2
        className=""
        title="Receive"
        handlePageBack={() =>
          navigate("/wallet", {
            state: { wallet: walletData.name, vault, lnCredentials, hash },
          })
        }
        handleCancel={() =>
          navigate("/wallet", {
            state: { wallet: walletData.name, vault, lnCredentials, hash },
          })
        }
        button={
          <RoundedButton
            className="w-full text-lg text-black dark:bg-yellow-500"
            onClick={() =>
              navigate("/wallet", {
                state: { wallet: walletData.name, vault, lnCredentials, hash },
              })
            }
          >
            Return to wallet
          </RoundedButton>
        }
      >
        <div className="flex flex-col justify-center w-11/12 m-auto space-y-4 xs:space-y-6">
          <RoundedButton
            className="text-base text-white bg-darkmode-700 lg:text-lg disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={() => setOption("Bitcoin")}
          >
            <div className="flex flex-col w-full px-3 py-1 sm:flex-row sm:flex-nowrap sm:px-6">
              <p className="pb-1 text-center sm:text-left sm:pb-0">
                Receive on-chain
              </p>
              <div className="flex mx-auto ml-auto sm:mr-0 flex-nowrap">
                <Bitcoin className="w-6 h-6 my-auto mr-1.5" />
                <p className="font-thin text-right text-gray-500">
                  Bitcoin (L1)
                </p>
              </div>
            </div>
          </RoundedButton>
          <RoundedButton
            className="text-base text-white bg-darkmode-700 lg:text-lg disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={() => setOption("Lightning")}
            disabled={DISABLE_LN || !LNDHUBX || network !== "bitcoin"}
          >
            <div className="flex flex-col w-full px-3 py-1 sm:flex-row sm:flex-nowrap sm:px-6">
              <p className="pb-1 text-center sm:text-left sm:pb-0">
                Receive through lightning
              </p>
              <div className="flex mx-auto ml-auto sm:mr-0 flex-nowrap">
                <LightningNetwork className="w-6 h-6 my-auto mr-1.5" />
                <p className="font-thin text-right text-gray-500">
                  Lightning (L2)
                </p>
              </div>
            </div>
          </RoundedButton>
          <RoundedButton
            className="hidden text-base text-white bg-darkmode-700 lg:text-lg disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={() =>
              tokensWalletFunded
                ? setOption("Asset")
                : setOpenFundAssetsModal(true)
            }
            disabled={!CARBONADO}
          >
            Receive Asset
          </RoundedButton>
          <RoundedButton
            className="hidden text-base text-white bg-darkmode-700 lg:text-lg disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={() => setOption("Invoice")}
          >
            Generate Invoice
          </RoundedButton>
        </div>
        <Modal open={openFundAssetsModal} setOpen={setOpenFundAssetsModal}>
          <FundAssets
            walletBalance={addBalance(walletData?.balance)}
            tokensWalletAddress={tokensWalletAddress}
            udasWalletAddress={udasWalletAddress}
            vault={vault}
            network={network}
            setOpenFundAssetsModal={setOpenFundAssetsModal}
          />
        </Modal>
      </PageWrapper2>
    );
  }

  return (
    <>
      {option === "Bitcoin" && (
        <PageWrapper2
          className=""
          handlePageBack={() => {
            navigate(locationHistory.pop()?.replace("#", "") || "/", {
              state: {
                ...location.state,
                wallet: walletData.name,
                lnCredentials,
              },
            });
          }}
          title={`Receive ${option}`}
        >
          <div className="flex flex-col my-auto">
            <div className="w-full h-auto py-4 mx-auto border-1 rounded-xl dark:border-darkmode-700 xs:py-6 sm:py-12">
              <div className="m-auto p-6 sm:p-9 bg-white rounded-xl flex justify-center max-w-[300px] sm:max-w-[400px]">
                <QRCode
                  value={walletData.address}
                  className="max-w-[275px] sm:max-w-[375px] sm:w-[90%] h-auto"
                  size={256}
                  viewBox="0 0 256 256"
                />
              </div>
            </div>
            <CopyButton
              title="Wallet Address"
              handleOnClick={() => {
                navigator.clipboard.writeText(walletData.address);
                setOpen(true);
              }}
            >
              {walletData.address}
            </CopyButton>
            <RoundedButton
              className="w-full mt-6 mr-2 text-base text-black bg-yellow-500"
              onClick={() =>
                navigate("/wallet", {
                  state: {
                    wallet: walletData.name,
                    vault,
                    lnCredentials,
                    hash,
                  },
                })
              }
            >
              Return to wallet
            </RoundedButton>
          </div>
        </PageWrapper2>
      )}
      {option === "Lightning" && (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {lnInvoice?.invoice ? (
            <PageWrapper2
              className=""
              handlePageBack={() => {
                navigate(locationHistory.pop()?.replace("#", "") || "/", {
                  state: {
                    ...location.state,
                    wallet: walletData.name,
                  },
                });
              }}
              title="LN Invoice Created"
              button={
                <RoundedButton
                  className="w-full mr-2 text-base text-black bg-yellow-500"
                  onClick={() =>
                    navigate("/wallet", {
                      state: {
                        wallet: walletData.name,
                        vault,
                        lnCredentials,
                        hash,
                      },
                    })
                  }
                >
                  Return to Wallet
                </RoundedButton>
              }
            >
              {lnTxPending ? (
                <>
                  <div className="w-full h-auto py-4 m-auto border-1 rounded-xl dark:border-darkmode-700 xs:py-6 sm:py-12">
                    <div className="m-auto p-6 sm:p-9 bg-white rounded-xl flex justify-center max-w-[300px] sm:max-w-[400px]">
                      <QRCode
                        value={lnInvoice.invoice}
                        className="max-w-[275px] sm:max-w-[375px] sm:w-[90%] h-auto m-auto"
                        size={256}
                        viewBox="0 0 256 256"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col w-full text-gray-800 dark:text-gray-300">
                    <div className="w-full pb-3 m-auto my-3 text-black sm:my-0 dark:text-gray-400 sm:pb-6">
                      <div className="grid grid-cols-2 border-b-1 dark:border-darkmode-700">
                        <p className="my-4 text-base font-thin text-left md:text-lg flex-grow-default dark:text-gray-500">
                          Note
                        </p>
                        <p className="my-4 text-base font-normal text-right truncate md:text-lg flex-grow-default">
                          {lnInvoice.description}
                        </p>
                      </div>
                      <div className="grid grid-cols-2 border-b-1 dark:border-darkmode-700">
                        <p className="my-4 text-base font-thin text-left md:text-lg flex-grow-default dark:text-gray-500">
                          Amount
                        </p>
                        <p className="my-4 text-base font-normal text-right truncate md:text-lg flex-grow-default">
                          {lnInvoice.amount.toLocaleString()}{" "}
                          {network !== "bitcoin" ? "tSats" : "Sats"}{" "}
                        </p>
                      </div>
                      <div className="flex flex-row flex-nowrap border-b-1 dark:border-darkmode-700">
                        <p className="my-4 text-base font-thin text-left md:text-lg flex-grow-default dark:text-gray-500">
                          Invoice
                        </p>
                        <CopyButton
                          handleOnClick={() => {
                            navigator.clipboard.writeText(lnInvoice.invoice);
                            setOpen(true);
                          }}
                          className="max-w-9/12"
                        >
                          {lnInvoice.invoice}
                        </CopyButton>
                      </div>
                    </div>
                  </div>{" "}
                </>
              ) : (
                <div className="w-full h-auto py-6 m-auto text-center border-1 rounded-xl dark:border-darkmode-600">
                  <CheckCircle className="w-24 h-24 m-auto mb-6" />
                  <h1 className="m-auto text-lg font-normal text-center text-black dark:text-white">
                    Invoice completed
                  </h1>
                </div>
              )}
            </PageWrapper2>
          ) : (
            <PageWrapper
              className=""
              handlePageBack={() => {
                navigate(locationHistory.pop()?.replace("#", "") || "/", {
                  state: {
                    ...location.state,
                    wallet: walletData.name,
                  },
                });
              }}
              title="Create Lightning Invoice"
              handleCancel={() =>
                navigate("/wallet", {
                  state: {
                    wallet: walletData.name,
                    vault,
                    lnCredentials,
                    hash,
                  },
                })
              }
              handleSubmit={async () => {
                try {
                  const tokens = await backend.lightning.auth(
                    lnCredentials.login,
                    lnCredentials.password
                  );
                  if ("error" in tokens) {
                    setError({
                      title: "Error Creating LN Invoice",
                      message: `Authentication error: ${tokens.error}`,
                    });
                    setOpenError(true);
                  } else {
                    const invoice = await backend.lightning.createInvoice(
                      lnInvoice.description,
                      lnInvoice.amount,
                      tokens.token
                    );
                    if (!invoice.error) {
                      setLnInvoice({
                        ...lnInvoice,
                        invoice: invoice.payment_request,
                      });
                    } else {
                      setError({
                        title: "Error Creating LN Invoice",
                        message: lndhubError(invoice.error),
                      });
                      setOpenError(true);
                    }
                  }
                } catch (err) {
                  setError({
                    title: "Error Creating LN Invoice",
                    message:
                      (err as Error)?.message ||
                      err?.toString() ||
                      "Unhandled exception",
                  });
                  setOpenError(true);
                }
              }}
            >
              <div className="w-full">
                <TextField
                  type="text"
                  label="description"
                  placeholder="Enter description"
                  className="w-full p-3"
                  onChange={(e) =>
                    setLnInvoice({ ...lnInvoice, description: e.target.value })
                  }
                />
                <TextField
                  type="number"
                  label="amount"
                  placeholder="Enter amount in Sats"
                  className="w-full p-3"
                  onChange={(e) =>
                    setLnInvoice({ ...lnInvoice, amount: e.target.value })
                  }
                />
              </div>
            </PageWrapper>
          )}
        </>
      )}
      {(option === "Asset" || option === "UDA") && (
        <PageWrapper2
          className=""
          handlePageBack={() =>
            navigate(locationHistory.pop()?.replace("#", "") || "/", {
              state: {
                ...location.state,
                wallet: walletData.name,
                locationHistory,
              },
            })
          }
          title={`Receive ${option}`}
          button={
            !rgbInvoice ? (
              <RoundedButton
                className="w-full text-base text-black bg-yellow-500"
                onClick={async () => {
                  setLoading(true);
                  const invoice = await generateInvoice(
                    vault,
                    contractId,
                    amount.toString(),
                    type,
                    assetUtxos
                  );
                  setRgbInvoice(invoice);
                  setLoading(false);
                }}
                disabled={loading}
                loading={loading}
              >
                Create Invoice
              </RoundedButton>
            ) : (
              <RoundedButton
                className="w-full text-base text-black bg-yellow-500"
                onClick={() =>
                  navigate("/wallet", {
                    state: {
                      wallet: walletData.name,
                      vault,
                      lnCredentials,
                      hash,
                    },
                  })
                }
              >
                Return to wallet
              </RoundedButton>
            )
          }
        >
          <div className="w-11/12 m-auto">
            {option === "Asset" && !rgbInvoice && (
              <TextField
                label="Amount"
                type="number"
                className="w-full p-3"
                onChange={async (e) => {
                  setAmount(e.target.value);
                }}
                placeholder="Enter amount"
              />
            )}
            {option === "UDA" && !rgbInvoice && (
              <TextField
                label="Contract ID"
                className="w-full p-3"
                onChange={async (e) => {
                  setContractId(e.target.value);
                }}
                placeholder="Enter contract ID"
              />
            )}
          </div>
          {rgbInvoice && (
            <>
              <div className="w-full h-auto py-4 m-auto border-1 rounded-xl dark:border-darkmode-700 xs:py-6 sm:py-12">
                <div className="m-auto p-6 sm:p-9 bg-white rounded-xl flex justify-center max-w-[300px] sm:max-w-[400px]">
                  <QRCode
                    className="max-w-[275px] sm:max-w-[375px] sm:w-[90%] h-auto m-auto"
                    value={rgbInvoice}
                    size={256}
                    viewBox="0 0 256 256"
                  />
                </div>
              </div>
              <CopyButton
                title="Invoice"
                handleOnClick={() => {
                  navigator.clipboard.writeText(rgbInvoice);
                  setOpen(true);
                }}
              >
                {rgbInvoice}
              </CopyButton>
            </>
          )}
        </PageWrapper2>
      )}
      {option === "Invoice" && (
        <PageWrapper2
          className=""
          handlePageBack={() => setOption("")}
          title={`Generate ${option}`}
          button={
            <RoundedButton
              className="w-full mr-2 text-base text-black bg-yellow-500"
              onClick={() =>
                navigate("/wallet", {
                  state: {
                    wallet: walletData.name,
                    vault,
                    hash,
                    lnCredentials,
                  },
                })
              }
            >
              Return to wallet
            </RoundedButton>
          }
        >
          <div className="w-full h-auto py-4 m-auto border-1 rounded-xl dark:border-darkmode-700 xs:py-6 sm:py-12">
            <div className="m-auto p-6 sm:p-9 bg-white rounded-xl flex justify-center max-w-[300px] sm:max-w-[400px]">
              <QRCode
                value={walletData.address}
                className="max-w-[275px] sm:max-w-[375px] sm:w-[90%] h-auto m-auto"
                size={256}
                viewBox="0 0 256 256"
              />
            </div>
          </div>
          <CopyButton
            title="Wallet Address"
            handleOnClick={() => {
              navigator.clipboard.writeText(walletData.address);
              setOpen(true);
            }}
          >
            {walletData.address}
          </CopyButton>
        </PageWrapper2>
      )}
      <CopiedToClipboard open={open} setOpen={setOpen} />
      <ErrorModal
        open={openError}
        setOpen={setOpenError}
        message={error.message}
        title={error.title}
      />
    </>
  );
};

export default Receive;
