/* eslint-disable no-unused-vars */
import React, { useRef, useState } from "react";

import Controls from "src/Components/AudioPlayer/Controls";
import ProgressBar from "src/Components/AudioPlayer/ProgressBar";
import DisplayTrack from "src/Components/AudioPlayer/DisplayTrack";

const AudioPlayback = ({ className = "", ...props }) => {
  const [trackIndex, setTrackIndex] = useState(0);
  const [timeProgress, setTimeProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const progressBarRef = useRef<HTMLDivElement | null>(null);

  return (
    <div className="absolute bottom-0 flex justify-center w-full h-auto pb-3 m-auto lg:pb-6 pt-44 sm:pt-56 bg-gradient-to-t from-black to-transparent">
      <div className="flex flex-col w-full h-auto">
        <DisplayTrack
          {...{
            currentTrack: props.track,
            audioRef: props.audioRef,
            setDuration,
            progressBarRef,
          }}
        />
        <ProgressBar
          {...{
            progressBarRef,
            audioRef: props.audioRef,
            timeProgress,
            duration,
          }}
        />
        <Controls
          {...{
            audioRef: props.audioRef,
            progressBarRef,
            duration,
            setTimeProgress,
            trackIndex,
            setTrackIndex,
          }}
        />
      </div>
    </div>
  );
};

export default AudioPlayback;
