/* global chrome */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as backend from "bitmask-core";
import { LNDHUBX, Network, init } from "bitmask-core/constants";

import RoundedButton from "src/Components/Buttons/RoundedButton";
import ErrorModal from "src/Components/Modals/Error";
import lib, { isExtension } from "src/lib";
import { setHash } from "src/Hooks/util";
import CheckCircle from "../Icons/CheckCircle";

const Success = ({ ...props }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState({
    title: "",
    message: "",
  });

  const handleSubmit = async () => {
    console.log("handleSubmit initiated.");

    try {
      const wallet = "wallet_01";
      const network = Network.bitcoin;
      console.log(`Switching network to: ${network}`);
      await backend.constants.switchNetwork(network);
      localStorage.setItem("network", network);
      console.log("Network switched and stored in localStorage.");

      const encryptedDescriptors = localStorage.getItem("bitcoinDescriptor");
      if (!encryptedDescriptors) {
        console.error("No descriptors found in localStorage.");
        throw new Error("No descriptors found");
      }
      console.log("Encrypted descriptors retrieved from localStorage.");

      const hash = backend.bitcoin.hashPassword(props.password);
      console.log(`Hashed password: ${hash}`);

      console.log("Decrypting wallet with hash.");
      const vault = await backend.bitcoin.decryptWallet(
        hash,
        encryptedDescriptors
      );
      console.log("Wallet decrypted successfully.");

      console.log("Setting public key hash in storage.");
      await lib.storageSet({ pubKeyHash: vault.public.xpubkh });

      console.log("Setting hash using setHash utility.");
      await setHash(hash, vault.public.xpubkh);

      const redirectToWallet = async (
        refreshToken: string,
        accessToken: string
      ) => {
        console.log("Redirecting to wallet with provided tokens.");
        if (isExtension) {
          console.log(
            "Running as extension. Sending message to reload scripts and tabs."
          );
          chrome.runtime.sendMessage({
            call: "reload_script_and_tabs",
          });
        }
        console.log("Navigating to /wallet route.");
        navigate("/wallet", {
          state: {
            wallet,
            vault,
            hash,
            lnCredentials: {
              login: vault.public.xpubkh,
              password: vault.private.xprvkh,
              refreshToken,
              accessToken,
            },
          },
        });
      };

      const handleLightningAuth = async (lndhubEndpoint: string) => {
        console.log(
          `Initializing backend with LNDHub endpoint: ${lndhubEndpoint}`
        );
        await init(lndhubEndpoint);

        let refresh = "";
        let token = "";
        if (LNDHUBX) {
          console.log("Authenticating with Lightning Network.");
          const tokens = await backend.lightning.auth(
            vault.public.xpubkh,
            vault.private.xprvkh
          );

          if ("error" in tokens) {
            console.error(
              "Error during Lightning Wallet Authorization:",
              tokens.error
            );
            setError({
              title: "Error Occurred on LN Wallet Authorization",
              message: tokens.error,
            });
            setOpen(true);
            return;
          }
          refresh = tokens.refresh;
          token = tokens.token;
          console.log(`Received tokens - Refresh: ${refresh}, Token: ${token}`);

          console.log("Creating new Nostr public key.");
          await backend.nostr.newNostrPubkey(vault.public.nostrPub, token);
          console.log("Nostr public key created successfully.");
        }

        console.log("Proceeding to redirectToWallet.");
        await redirectToWallet(refresh, token);
      };

      if (LNDHUBX) {
        console.log("LNDHUBX is enabled. Handling Lightning authentication.");
        if (process.env.TEST_LNDHUB_ENDPOINT) {
          console.log("Handling Lightning Auth for Testnet.");
          handleLightningAuth("testnet");
        }
        if (process.env.PROD_LNDHUB_ENDPOINT) {
          console.log("Handling Lightning Auth for Bitcoin.");
          handleLightningAuth("bitcoin");
        }
      } else {
        console.log(
          "LNDHUBX is not enabled. Redirecting to wallet without Lightning credentials."
        );
        await redirectToWallet("", "");
      }

      console.log("handleSubmit completed successfully.");
    } catch (err) {
      console.error("Error in Success.handleSubmit:", err);
      setError({
        title:
          (err as Error)?.name || "Unhandled exception in Success handleSubmit",
        message:
          (err as Error)?.message || err?.toString() || "Unhandled exception",
      });
      setOpen(true);
      if (err?.toString().toLowerCase().includes("invalid")) {
        console.info("Deleting descriptor due to parse error.");
        window.localStorage.removeItem("descriptor");
      }
    }
  };

  return (
    <div className="w-full h-auto max-w-2xl pb-10 m-auto mx-3 text-center xs:mx-6 sm:m-auto">
      <CheckCircle className="w-20 h-20 mx-auto mb-6" />

      <h1 className="text-2xl font-medium text-center text-gray-900 xs:text-3xl dark:text-gray-300 flex-grow-default">
        Congratulations!
      </h1>
      <p className="my-3 text-base font-thin text-left text-gray-500 flex-grow-default sm:text-lg">
        You have your very own BitMask wallet! Get ready for all of the
        incredible adventures to come. Get started now by opening the BitMask
        extension on your web browser. Or by using the BitMask Web Wallet at{" "}
        <a className="underline" href="https://bitmask.app">
          bitmask.app
        </a>
      </p>
      <RoundedButton
        className="w-full py-1 mx-auto mt-8 text-base font-medium text-gray-900 bg-yellow-500 sm:text-lg"
        onClick={() => handleSubmit()}
      >
        Launch
      </RoundedButton>

      <ErrorModal
        open={open}
        setOpen={setOpen}
        message={error.message}
        title={error.title}
      />
    </div>
  );
};

export default Success;
