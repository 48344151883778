/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* global chrome */

import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  Suspense,
} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import * as backend from "bitmask-core";
import { Activity, WalletData } from "bitmask-core/bitcoin";
import { LnWalletData } from "bitmask-core/lightning";
import { CARBONADO, LNDHUBX, init } from "bitmask-core/constants";

import { MainNavContext } from "src/Hooks/MainNavContext";
import Copy from "src/Components/Icons/Copy";
import Tabs from "src/Components/Tabs/Tabs";
import CopiedToClipboard from "src/Components/Modals/CopiedToClipboard";
import Modal from "src/Components/Modals/Modal";
import ReleaseNotes from "src/Components/Modals/Children/ReleaseNotes";
import Loader from "src/Components/Loaders/Loader";
import { getConversion, classNames, addBalance } from "src/Hooks/util";
import lib, { isExtension } from "src/lib";
import FundAssets from "src/Components/Modals/Children/FundAssets";
import { Asset, Collection, Location } from "src/types";
import { ifaceFungibles, ifaceUdas, releaseNotes } from "src/constants";
import AssetFooter from "src/Components/Layout/Footers/AssetFooter";
import Banner from "src/Components/Modals/Banner";
import { importWallet } from "src/Pages/WalletImport";
import ErrorModal from "src/Components/Modals/Error";
import {
  ContractResponse,
  listTransfers,
  RgbInvoiceStatusResponse,
  RgbTransferItem,
  WatcherWalletResponse,
} from "bitmask-core/rgb";
import IconAndLabelButton from "src/Components/Buttons/IconAndLabelButton";
import ArrowUp from "src/Components/Icons/ArrowUp";
import ArrowDown from "src/Components/Icons/ArrowDown";
import Swap from "src/Components/Icons/Swap";
import Balances from "src/Components/MainWallet/Balances";

const Activities = React.lazy(
  () => import("../Components/MainWallet/Activities")
);
const Assets = React.lazy(() => import("../Components/MainWallet/Assets"));
const Collections = React.lazy(
  () => import("../Components/MainWallet/Collections")
);

const sortActivitiesData = (
  data: WalletData,
  lnActivities: backend.bitcoin.Activity[] | null
) => {
  if (!data?.transactions) {
    return [];
  }
  const layerOneActivitiesData = data.transactions.map((transaction) => {
    let date = Math.round(Date.now() / 1000);
    if (transaction.confirmationTime !== null)
      date = transaction.confirmationTime.timestamp;
    const activity: Activity = {
      ...transaction,
      date,
      id: transaction.txid,
      assetType: "sats",
      amount:
        transaction.sent === 0
          ? transaction.received
          : transaction.sent - transaction.received - transaction.fee,
      fee: transaction.fee,
      action: transaction.sent === 0 ? "received" : "sent",
      status: transaction.confirmed ? "confirmed" : "pending",
    };
    return activity;
  });
  const sortedActivities = [...layerOneActivitiesData];
  if (lnActivities) {
    sortedActivities.push(...lnActivities);
  }
  sortedActivities.sort((a, b) => {
    if (a.date > b.date) return -1;
    if (a.date < b.date) return 1;
    return 0;
  });

  return sortedActivities;
};

const Wallet = () => {
  const navigate = useNavigate();
  const location = useLocation() as Location;
  const { wallet, vault, lnCredentials, hash } = location?.state ?? {};

  let { network } = location?.state ?? {};

  if (!network) {
    const networkStore = localStorage.getItem("network");
    if (!networkStore) {
      console.debug("network store undefinied");
      network = "bitcoin";
    } else {
      network = networkStore;
    }
  }

  const { setMainNav } = useContext(MainNavContext);
  const [loading, setLoading] = useState(false);
  const [loadingBitcoin, setLoadingBitcoin] = useState(false);
  const [loadingLightning, setLoadingLightning] = useState(false);
  const [loadingActivities, setLoadingActivities] = useState(false);
  const [loadingAssets, setLoadingAssets] = useState(false);
  const [loadingUda, setLoadingUda] = useState(false);
  const [loadingVault, setLoadingVault] = useState(true);
  const [tokensWalletAddress, setTokensWalletAddress] = useState("");
  const [tokensWalletFunded, setTokensWalletFunded] = useState(false);
  const [udasWalletAddress, setUdasWalletAddress] = useState("");
  const [openFundAssetsModal, setOpenFundAssetsModal] = useState(false);
  const [syncingWallet, setSyncingWallet] = useState(false);
  const [view, setView] = useState(0);
  const [openVersionPopup, setOpenVersionPopup] = useState(false);
  const [conversion, setConversion] = useState(0);
  const [openCopyModal, setOpenCopyModal] = useState(false);
  const [openBanner, setOpenBanner] = useState(false);
  const [assetUtxos, setAssetUtxos] = useState({
    currentUtxoTokens: "",
    currentUtxoUdas: "",
  });
  const [walletData, setWalletData] = useState<WalletData>(
    localStorage.getItem(`${network}WalletData`)
      ? JSON.parse(localStorage.getItem(`${network}WalletData`) || "")
      : []
  );
  const [walletAddress, setWalletAddress] = useState("");
  const [lnWalletData, setLnWalletData] = useState<LnWalletData>();
  const [activities, setActivities] = useState<Activity[]>();
  const [assets, setAssets] = useState<Asset[]>(
    localStorage.getItem(`${network}Assets`)
      ? JSON.parse(localStorage.getItem(`${network}Assets`) || "")
      : []
  );
  const [collections, setCollections] = useState<Collection[]>(
    localStorage.getItem(`${network}Udas`)
      ? JSON.parse(localStorage.getItem(`${network}Udas`) || "")
      : []
  );
  const [invoices, setInvoices] = useState<RgbInvoiceStatusResponse[]>([]);
  const [transfers, setTransfers] = useState<RgbTransferItem[]>([]);
  // const [thereAreOpenSealsOrInvoices, setThereAreOpenSealsOrInvoices] =
  //   useState(false);
  const [displayedError, setError] = useState({
    title: "",
    message: "",
  });
  const [openError, setOpenError] = useState(false);
  const [pendingAssetTransfers, setPendingAssetTransfers] = useState(true);
  const [pendingUdaTransfers, setPendingUdaTransfers] = useState(true);
  const [fundingPending, setFundingPending] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  const { VERSION, CONTEXT = "" } = process.env;

  // Storage accessors
  const walletDataKey = `${network}.wallet.bitcoin`;
  const lnWalletKey = `${network}.LnWalletData`;
  const txHistoryKey = `${network}.TransactionHistory`;
  const assetsKey = `${network}.Assets`;
  const udasKey = `${network}.Udas`;
  const udasWalletDataKey = `${network}.wallet.udas`;

  const retrieveWalletData = async (): Promise<WalletData> => {
    const result = (await lib.storageGet<WalletData>([walletDataKey]))[
      walletDataKey
    ];
    if (result) setWalletData(result);
    return result;
  };

  const retrieveLnWalletData = async (): Promise<LnWalletData> => {
    const result = (await lib.storageGet<LnWalletData>([lnWalletKey]))[
      lnWalletKey
    ];
    if (result) setLnWalletData(result);
    return result;
  };

  const retrieveActivities = async (): Promise<Activity[]> => {
    const result = (await lib.storageGet<Activity[]>([txHistoryKey]))[
      txHistoryKey
    ];
    if (result) setActivities(result);
    return result;
  };

  const retrieveAssets = async (): Promise<Asset[]> => {
    const result = (await lib.storageGet<Asset[]>([assetsKey]))[assetsKey];
    if (result) setAssets(result);
    return result;
  };

  const retrieveUdas = async (): Promise<Collection[]> => {
    const result = (await lib.storageGet<Collection[]>([udasKey]))[udasKey];
    if (result) setCollections(result);
    console.log(result, "collections");
    return result;
  };

  const retrieveInvoices = async (): Promise<RgbInvoiceStatusResponse[]> => {
    const result = (
      await lib.storageGet<RgbInvoiceStatusResponse[]>([`${network}Invoices`])
    )[`${network}Invoices`];
    if (result) setInvoices(result);
    return result;
  };

  const retrieveTransfers = async (): Promise<RgbTransferItem[]> => {
    const result = (
      await lib.storageGet<RgbTransferItem[]>([`${network}Transfers`])
    )[`${network}Transfers`];
    if (result) setTransfers(result);
    return result;
  };

  const storeWalletData = async (newWalletData: WalletData) => {
    setWalletData(newWalletData);
    await lib.storageSet({ [walletDataKey]: newWalletData });
    setLoadingBitcoin(false);
  };

  const storeLnWalletData = async (newLnWalletData: LnWalletData) => {
    setLnWalletData(newLnWalletData);
    console.debug("storing LN wallet data", newLnWalletData);
    await lib.storageSet({ [lnWalletKey]: newLnWalletData });
    setLoadingLightning(false);
  };

  const storeActivities = async (newActivities: Activity[]) => {
    setActivities(newActivities);
    console.debug("storing wallet tx data", newActivities);
    await lib.storageSet({
      [txHistoryKey]: newActivities,
    });
    setLoadingActivities(false);
  };

  const storeUdas = async (newUdas: Collection[]) => {
    setCollections(newUdas);
    console.debug("storing UDAs data", newUdas);
    await lib.storageSet({
      [udasKey]: newUdas,
    });
    setLoadingUda(false);
  };

  const storeAssets = async (newAssets: Asset[]) => {
    setAssets(newAssets);
    console.debug("storing assets data", newAssets);
    await lib.storageSet({
      [assetsKey]: newAssets,
    });
    setLoadingAssets(false);
  };

  const storeInvoices = async (newInvoices: RgbInvoiceStatusResponse[]) => {
    console.debug("storing invoices data", newInvoices);
    await lib.storageSet({
      [`${network}Invoices`]: newInvoices,
    });
  };

  const storeTransfers = async (newTransfers: RgbTransferItem[]) => {
    console.debug("storing transfers data", newTransfers);
    setPendingAssetTransfers(
      newTransfers.some((transfer) => {
        if (
          !transfer.direction.out?.txStatus.block &&
          transfer.iface === ifaceFungibles
        ) {
          return true;
        }
        return false;
      })
    );
    setPendingUdaTransfers(
      transfers.some((transfer) => {
        if (
          !transfer.direction.out?.txStatus.block &&
          transfer.iface === ifaceUdas
        ) {
          return true;
        }
        return false;
      })
    );

    await lib.storageSet({
      [`${network}Transfers`]: newTransfers,
    });
  };

  // LN
  const lnAuth = async () => {
    if (
      lnCredentials?.login?.length > 0 &&
      lnCredentials?.password?.length > 0
    ) {
      const tokens = await backend.lightning.auth(
        lnCredentials.login,
        lnCredentials.password
      );
      return tokens;
    }
    throw new Error("Not logged in");
  };

  const getLightningWalletData = async () => {
    console.debug("getLightningWalletData called");
    try {
      const tokens = await lnAuth();
      let token = "";

      if ("error" in tokens && tokens.error === "UserDoesNotExist") {
        console.warn("Handling UserDoesNotExist in getLightningWalletData...");
        await backend.lightning.createWallet(
          vault.public.xpubkh,
          vault.private.xprvkh
        );
        const retryTokens = await backend.lightning.auth(
          vault.public.xpubkh,
          vault.private.xprvkh
        );
        if ("error" in retryTokens) {
          console.error(
            "Error Authorizing Lightning Wallet",
            retryTokens.error
          );
          setError({
            title: "Error Authorizing Lightning Wallet (retry)",
            message: retryTokens.error,
          });
          setOpenError(true);
          return null;
        }
        token = retryTokens.token;
      } else if ("error" in tokens) {
        console.error(
          "Error Authorizing Lightning Wallet (outer)",
          tokens.error
        );
        setError({
          title: "Error Authorizing Lightning Wallet",
          message: tokens.error,
        });
        setOpenError(true);
        return null;
      } else {
        token = tokens.token;
      }

      const lnAccount = await backend.lightning.getBalance(token);
      const lnTxs = await backend.lightning.getTxs(token);

      console.debug("getLightningWalletData results", { lnAccount, lnTxs });

      const lnActivitiesData = lnTxs.map((transaction) => {
        let date = Math.round(Date.now() / 1000);
        if (transaction.created_at !== null)
          date = Number(transaction.created_at) / 1000;
        const activity: Activity = {
          ...transaction,
          lightning: true,
          date,
          id: transaction.txid,
          assetType: "sats",
          amount:
            (Number(transaction.outbound_amount) - Number(transaction.fees)) *
            100000000,
          fee: Number(transaction.fees) * 100000000,
          action:
            transaction.outbound_account_id === lnAccount[0].account_id
              ? "sent"
              : "received",
          status: "confirmed",
          received: 0,
          sent: 0,
          confirmed: false,
          confirmationTime: {
            height: 0,
            timestamp: 0,
          },
          vsize: 0,
          feeRate: 0,
        };
        return activity;
      });
      await storeLnWalletData({
        balance: lnAccount[0],
        transactions: lnTxs,
      });
      return lnActivitiesData;
    } catch (error) {
      console.error(error, "ln error");
      if (error?.toString().includes("/auth")) {
        try {
          // Error is likely caused by account not present on LNDHubX
          await backend.lightning.createWallet(
            vault.public.xpubkh,
            vault.private.xprvkh
          );
          return getLightningWalletData();
        } catch (err) {
          console.error(
            "error attempting to create an account in getLightningWalletData catch",
            err
          );
          localStorage.setItem("isRecoveryState", "in recovery");
          await errorCompensation(error as Error, "getLightningWalletData");
          return null;
        }
      }
      localStorage.setItem("isRecoveryState", "in recovery");
      await errorCompensation(error as Error, "getLightningWalletData");
      return null;
    }
  };

  // Error handling
  const errorCompensation = async (error: Error, context: string) => {
    console.debug(
      "entering error compensation for",
      error,
      "in context",
      context
    );
    if (
      error?.toString().toLowerCase().includes("invalid network") ||
      error?.toString().toLowerCase().includes("missing field `uid`")
    ) {
      console.debug("compensating by reimporting the wallet");
      try {
        const mainnetDescriptors =
          window.localStorage.getItem("bitcoinDescriptor");
        const newVault = await backend.bitcoin.decryptWallet(
          hash,
          mainnetDescriptors as string
        );

        const isRecoveryState = localStorage.getItem("isRecoveryState");
        localStorage.setItem("isRecoveryState", "in recovery");

        await importWallet({
          hash,
          words: newVault.mnemonic,
          navigate,
          reload: !isRecoveryState,
        });
      } catch (innerError) {
        console.error("failed to compensate", innerError);
        localStorage.removeItem("isRecoveryState");
        setLoading(false);
        setOpenError(true);
        setError({
          title:
            (innerError as Error)?.name ||
            `Unhandled exception in ${context} error compensation`,
          message:
            (innerError as Error)?.message ||
            innerError?.toString() ||
            `Unhandled exception in ${context} error compensation`,
        });
      }
    } else {
      setError({
        title: `Unhandled exception in ${context}`,
        message:
          error?.message ||
          error?.toString() ||
          `Unhandled exception in ${context}`,
      });
      setOpenError(true);
    }
  };

  // Bitcoin
  const getBtcPrice = async () => {
    const rate = await getConversion();
    setConversion(rate);
  };

  /* eslint-disable */
  const getBitcoinWallet = async () => {
    console.debug("getBitcoinWallet called");
    setLoadingBitcoin(true);
    setLoadingLightning(true);
    setLoadingActivities(true);
    let lnActivities: backend.bitcoin.Activity[] | null = null;
    if (LNDHUBX && network === "bitcoin") {
      lnActivities = await getLightningWalletData();
      console.debug("getLightningWalletData success!");
    } else {
      console.debug(
        "skipping getLightningWalletData due to LNDHUBX being false"
      );
    }
    if (window.localStorage.getItem("loadNetworkChange")) {
      setLoading(true);
    }
    const addressBitcoin = await backend.bitcoin.getNewAddress(
      vault.public.btcDescriptorXpub
    );
    setWalletAddress(addressBitcoin);

    const walletData = await retrieveWalletData();

    if (walletData) {
      await storeActivities(sortActivitiesData(walletData, lnActivities));
    }

    const encryptedDescriptors = window.localStorage.getItem(
      `${network}Descriptor`
    );
    const getWalletDataVault = await backend.bitcoin.decryptWallet(
      hash,
      encryptedDescriptors as string
    );
    const { btcDescriptorXprv, btcChangeDescriptorXprv } =
      getWalletDataVault.private;

    console.debug("getWalletData try block");
    try {
      const response = await backend.bitcoin.getWalletData(
        btcDescriptorXprv,
        btcChangeDescriptorXprv
      );
      const data = { ...response, name: wallet };
      console.debug("getWalletData data", data);
      await storeWalletData(data);
      console.debug("Wallet xpubkh:", vault.public.xpubkh);

      await storeActivities(sortActivitiesData(data, lnActivities));

      if (window.localStorage.getItem("loadNetworkChange") === "true") {
        window.localStorage.removeItem("loadNetworkChange");
      }
      setLoading(false);
    } catch (error) {
      await errorCompensation(error as Error, "getWalletData");
    }
  };

  // RGB tokens
  /* eslint-disable */
  const getRgbWallet = async () => {
    const {
      nextUtxo,
      nextAddress,
      listContracts,
      invoices,
    }: WatcherWalletResponse = await backend.rgb.getWatcher(
      vault.private.nostrPrv
    );

    for (const contract of listContracts) {
      const { iface, balance, allocations }: ContractResponse = JSON.parse(
        await backend.get_contract(vault.private.nostrPrv, contract.contractId)
      );

      console.log(
        `contract: [${iface}] ${
          contract.contractId
        } / allocation: ${JSON.stringify(balance.uda)}`
      );

      console.log(
        `contract allocations: (${JSON.stringify(allocations, null, 2)})`
      );
    }
    console.log("nextAddress", nextAddress);
    console.log("nextUtxo", nextUtxo);
    console.log("invoices", invoices);
    // Next Address
    updateAddress(nextAddress);

    // Update Transfers
    await updateTransfers([]);

    // Next Utxo
    await updateUtxo(nextUtxo);

    // List Contract
    await updateContracts(listContracts || []);

    // Update Invoices
    await updateInvoices(invoices || []);
  };

  const updateAddress = (nextAddress?: string) => {
    // Next Address
    const rgbAddress = nextAddress || "";
    setUdasWalletAddress(rgbAddress);
    setTokensWalletAddress(rgbAddress);
  };

  const updateUtxo = async (nextUtxo?: string) => {
    // Next Utxo
    const { funded } = await lib.storageGet(["funded"]);
    const isFunded = !!nextUtxo;
    if (isFunded) {
      setAssetUtxos({
        currentUtxoTokens: nextUtxo,
        currentUtxoUdas: nextUtxo,
      });
    }

    if (isFunded) {
      setLoadingVault(false);
      setTokensWalletFunded(true);
      setFundingPending(false);
    } else if (funded && !isFunded) {
      setLoadingVault(false);
      setTokensWalletFunded(true);
      setFundingPending(true);
    } else {
      setLoadingVault(false);
      setTokensWalletFunded(false);
      setFundingPending(true);
    }
  };

  const updateContracts = async (listContracts: ContractResponse[]) => {
    setLoadingAssets(true);
    setLoadingUda(true);
    if (!CARBONADO) return;

    // Retrieve and display currently saved assets and collections
    const savedData = await lib.storageGet([assetsKey, udasKey]);
    setAssets((savedData[assetsKey] as ContractResponse[]) || []);
    setCollections((savedData[udasKey] as Collection[]) || []);
    try {
      const newAssets = listContracts.filter(
        (contract) => contract.iface === ifaceFungibles
      );
      await lib.storageSet({ [assetsKey]: newAssets });
      setAssets(newAssets);
      const newUdas = listContracts.filter(
        (contract) =>
          contract.iface === ifaceUdas &&
          contract.balance.uda &&
          contract.balance.uda.fraction > 0
      );

      const udaCollections = {};
      newUdas.forEach((collection) => {
        const { ticker } = collection;
        if (!udaCollections[ticker]) {
          udaCollections[ticker] = [];
        }
        udaCollections[ticker].push(collection);
      });
      const newCollections: Collection[] = Object.entries(udaCollections).map(
        ([key, value]) => ({
          name: key === "DIBAUDA" ? "no collection" : key,
          id: key,
          udas: value as ContractResponse[],
        })
      );

      await lib.storageSet({ [udasKey]: newCollections });
      setCollections(newCollections);
    } catch (error) {
      await errorCompensation(error as Error, "updateContracts");
    } finally {
      setLoadingAssets(false);
      setLoadingUda(false);
    }
  };

  const updateInvoices = async (newInvoices: RgbInvoiceStatusResponse[]) => {
    // Update Invoices
    console.debug(`avalaible invoices: ${JSON.stringify(newInvoices)}`);
    await storeInvoices(newInvoices);
    await retrieveInvoices();
  };

  const updateTransfers = async (newTransfers: RgbTransferItem[]) => {
    // Update Transfers
    const transfersHist =
      newTransfers.length > 0
        ? newTransfers
        : await listTransfers(vault.private.nostrPrv);

    console.debug(`avalaible transfers: ${JSON.stringify(transfersHist)}`);
    await storeTransfers(transfersHist);
    await retrieveTransfers();
  };

  const syncNonce = JSON.parse(localStorage.getItem("syncNonce") || "0");

  useEffect(() => {
    (async () => {
      const urlParams = new URLSearchParams(window.location.search);

      if (isExtension) {
        chrome.storage.local.set({ network });
      }
      const handleSyncWallet = async () => {
        if (
          !location.state ||
          !location.state.vault ||
          !location.state.wallet ||
          !location.state.lnCredentials ||
          !localStorage.getItem(`${network}Descriptor`)
        ) {
          navigate("/", { replace: true });
        } else if (urlParams.get("page")) {
          localStorage.setItem("loggedIn", "true");
          const params = window.location.href
            .split("?")[1]
            .split("#")[0]
            .split("&")
            .map((e) => e.split("=")[1]);
          const { actionsTitle, actionsDescription } =
            await chrome.storage.local.get([
              "actionsTitle",
              "actionsDescription",
            ]);

          const handleNavigation = (action) =>
            navigate("/marketplaceaction", {
              replace: true,
              state: {
                message: params[0],
                tabid: parseInt(params[1] as string, 10),
                pubkeyHash: params[2],
                id: params[3],
                uid: params[4],
                vault,
                walletDataUdas: action.data,
                hash,
                marketplaceAction: {
                  title: action.title,
                  description: action.description,
                  call: action.call,
                },
              },
            });
          switch (urlParams.get("page")) {
            case "swap_offer":
              handleNavigation({
                title: actionsTitle,
                description: actionsDescription,
                call: "swap_offer",
              });
              break;
            case "cancel_swap_offer":
              handleNavigation({
                title: actionsTitle,
                description: actionsDescription,
                call: "cancel_swap_offer",
              });
              break;
            case "cancel_swap_offer":
              handleNavigation({
                title: actionsTitle,
                description: actionsDescription,
                call: "cancel_swap_offer",
              });
              break;
            case "swap_bid":
              handleNavigation({
                title: actionsTitle,
                description: actionsDescription,
                call: "swap_bid",
              });
              break;
            case "cancel_swap_bid":
              handleNavigation({
                title: actionsTitle,
                description: actionsDescription,
                call: "cancel_swap_bid",
              });
              break;
            case "cancel_swap_bid":
              handleNavigation({
                title: actionsTitle,
                description: actionsDescription,
                call: "cancel_swap_bid",
              });
              break;
            case "get_invoice":
              handleNavigation({
                title: actionsTitle,
                description: actionsDescription,
                call: "get_invoice",
              });
              break;
            case "issue_uda":
              // eslint-disable-next-line no-case-declarations
              const result = (
                await lib.storageGet<WalletData>([udasWalletDataKey])
              )[udasWalletDataKey];
              handleNavigation({
                title: actionsTitle,
                description: actionsDescription,
                call: "issue_uda",
                data: result,
              });
              break;
            case "get_vault":
              handleNavigation({
                title: actionsTitle,
                description: actionsDescription,
                call: "get_vault",
              });
              break;
            case "get_username":
              handleNavigation({
                title: actionsTitle,
                description: actionsDescription,
                call: "get_username",
              });
              break;
            default:
              break;
          }
        } else if (Object.keys(vault).length) {
          localStorage.setItem("loggedIn", "true");
          if (
            !localStorage.getItem(`newVersionInfoPopup${VERSION}${CONTEXT}`)
          ) {
            localStorage.setItem(
              `newVersionInfoPopup${VERSION}${CONTEXT}`,
              "true"
            );
            setOpenVersionPopup(true);
          }
          const walletInfo = await retrieveWalletData();
          const lnWalletInfo = await retrieveLnWalletData();
          const transactionHistory = await retrieveActivities();
          const ownedAssets = await retrieveAssets();
          const walletInvoices = await retrieveInvoices();
          const ownedUdas = await retrieveUdas();

          if (ownedUdas && Array.isArray(ownedUdas)) {
            await storeUdas(ownedUdas);
          }

          if (walletInfo) {
            await storeWalletData(walletInfo);
          }

          if (ownedAssets && walletInvoices) {
            await retrieveAssets();
            await retrieveInvoices();
          }

          console.debug("debug wallet LN", { lnWalletInfo });

          if (lnWalletInfo && typeof lnWalletInfo === "object")
            storeLnWalletData(lnWalletInfo);

          if (transactionHistory && typeof transactionHistory === "object")
            await storeActivities(transactionHistory);

          // i'm gonna store a simple boolean variable to check if this process is being executed
          if (!syncingWallet) {
            setSyncingWallet(true);
            await init();
            await Promise.all([
              getRgbWallet(),
              getBitcoinWallet(),
              getBtcPrice(),
              await backend.verify_transfers(vault.private.nostrPrv),
            ]);
            setSyncingWallet(false);
          }

          if (!localStorage.getItem("showedSeedRecoveryPrompt"))
            setOpenBanner(true);

          const wait = 120; // in seconds
          setTimeout(async () => {
            if (!syncingWallet) {
              setSyncingWallet(true);
              await Promise.all([
                getRgbWallet(),
                getBitcoinWallet(),
                getBtcPrice(),
                await backend.verify_transfers(vault.private.nostrPrv),
              ]);
              setSyncingWallet(false);
            }
          }, wait * 1000);
        } else {
          navigate("/", { replace: true });
        }
      };
      try {
        await handleSyncWallet();
      } catch (error) {
        console.error(error);
        setLoading(false);
        /* setOpenError(true);
        setError({
          title:
            (error as Error)?.name || "Unhandled exception in handleSyncWallet",
          message:
            (error as Error)?.message ||
            error?.toString() ||
            "Unhandled exception",
        }); */
      }
    })();
  }, [network, syncNonce]);

  useEffect(() => {
    (async () => {
      if (LNDHUBX && network === "bitcoin") {
        try {
          await init();
          await lnAuth();
        } catch (e) {
          console.warn("Failed initial LN auth", e);
        }
      }
    })();
  }, [LNDHUBX]);

  useEffect(() => {
    if (setMainNav) setMainNav(true);
  }, []);

  return (
    <div className="flex flex-col w-full mx-auto overflow-x-hidden text-gray-900 h-page dark:text-gray-300 dark:bg-newdarkmode-900">
      {openBanner && (
        <Banner
          setOpen={setOpenBanner}
          handleClose={() =>
            localStorage.setItem("showedSeedRecoveryPrompt", "true")
          }
        >
          If you haven&#39;t already saved your seed phrase, you can retrieve it
          in settings using your password
        </Banner>
      )}
      <div className="flex w-full h-full overflow-y-auto scrollbar dark:darkscrollbar md:mt-3">
        <div
          className={classNames(
            view === 0
              ? "min-h-[calc(100% - 56px)]"
              : "min-h-[calc((var(--vh) * 100)]",
            "h-auto min-h-full flex flex-col w-full md:px-6 max-w-4xl mx-auto"
          )}
        >
          <div ref={ref} className="flex flex-col w-full h-auto">
            <div
              className={classNames(
                process.env.CONTEXT === "MIXD Future Factory"
                  ? "hidden"
                  : "flex",
                "w-full flex-nowrap"
              )}
            >
              <div className="flex flex-col w-full pt-2 sm:pt-4 xs:pb-2 xs:py-6 md:rounded-t-2xl">
                <div className="w-full px-6 m-auto xs:w-9/12 lg:w-6/12 grid grid-cols-2 gap-3">
                  <button
                    type="button"
                    title="Copy wallet address"
                    onClick={() => {
                      navigator.clipboard.writeText(walletAddress || "");
                      setOpenCopyModal(true);
                    }}
                    className={classNames(
                      localStorage.getItem("username") ? "" : "col-span-2",
                      "rounded-full shadow-sm px-2 py-0.5 sm:py-1 flex bg-gray-300 dark:bg-yellow-500 dark:bg-opacity-15 font-thin cursor-pointer text-xs text-center w-full m-auto flex-nowrap text-yellow-500 dark:hover:bg-opacity-10 dark:text-yellow-500 min-h-[32px]"
                    )}
                    disabled={walletAddress === ""}
                  >
                    {walletAddress != "" ? (
                      <>
                        <p className="flex flex-1 w-auto mx-2 my-auto text-center truncate">
                          {walletAddress}
                        </p>
                        <Copy className="flex flex-none w-6 h-6" />{" "}
                      </>
                    ) : (
                      <p className="w-auto m-auto text-center truncate">
                        loading address...
                      </p>
                    )}
                  </button>
                  {localStorage.getItem("username") && (
                    <button
                      type="button"
                      title="Copy username"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          localStorage.getItem("username") || ""
                        );
                        setOpenCopyModal(true);
                      }}
                      className="rounded-full shadow-sm px-2 py-0.5 sm:py-1 flex bg-gray-300 dark:bg-yellow-500 dark:bg-opacity-15 font-thin cursor-pointer text-xs text-center w-full m-auto flex-nowrap text-yellow-500 dark:hover:bg-opacity-10 dark:text-yellow-500"
                    >
                      <p className="w-auto mx-auto my-auto text-center truncate">
                        {localStorage.getItem("username")}
                      </p>
                      <Copy className="flex flex-none w-6 h-6" />{" "}
                    </button>
                  )}
                </div>
                <Balances
                  loadingBitcoin={loadingBitcoin}
                  walletData={walletData}
                  network={network}
                  conversion={conversion}
                  loading={loading}
                  loadingLightning={loadingLightning}
                  lnWalletData={lnWalletData}
                />
                <div className="flex order-2 w-full m-auto flex-grow-default md:order-1">
                  <div className="flex flex-row justify-center w-full m-auto flex-nowrap gap-9">
                    <IconAndLabelButton
                      className="text-base text-black bg-yellow-500 lg:text-lg"
                      onClick={() => {
                        navigate("/send", {
                          state: {
                            locationHistory: [window.location.hash],
                            type: "",
                            walletData,
                            assetUtxos,
                            vault,
                            lnCredentials,
                            hash,
                            asset: {
                              allocations: [],
                              balance: addBalance(walletData?.balance),
                              dollarBalance: (
                                addBalance(walletData?.balance) * conversion
                              ).toFixed(3),
                              id: "",
                              name: "Satoshis",
                              ticker: "Sats",
                            },
                          },
                        });
                      }}
                      label="Send"
                    >
                      <ArrowUp className="w-4 h-4 font-thin text-black sm:w-5 sm:h-5" />
                    </IconAndLabelButton>
                    <IconAndLabelButton
                      className="text-base text-black bg-yellow-500 lg:text-lg"
                      onClick={() => {
                        navigate("/receive", {
                          state: {
                            walletData,
                            assetUtxos,
                            vault,
                            lnCredentials,
                            hash,
                            locationHistory: [window.location.hash],
                            tokensWalletFunded,
                            loadingVault,
                            tokensWalletAddress,
                            udasWalletAddress,
                          },
                        });
                      }}
                      label="Receive"
                    >
                      <ArrowDown className="w-4 h-4 font-thin text-black sm:w-5 sm:h-5" />
                    </IconAndLabelButton>
                    <IconAndLabelButton
                      className="text-base text-black bg-yellow-500 lg:text-lg"
                      onClick={() => {
                        navigate("/send", {
                          state: {
                            locationHistory: [window.location.hash],
                            type: "",
                            walletData,
                            assetUtxos,
                            vault,
                            lnCredentials,
                            hash,
                            asset: {
                              allocations: [],
                              balance: addBalance(walletData?.balance),
                              dollarBalance: (
                                addBalance(walletData?.balance) * conversion
                              ).toFixed(3),
                              id: "",
                              name: "Satoshis",
                              ticker: "Sats",
                            },
                          },
                        });
                      }}
                      label="Swap"
                      disabled
                    >
                      <Swap className="w-4 h-4 font-thin text-black sm:w-5 sm:h-5" />
                    </IconAndLabelButton>
                  </div>
                </div>
              </div>
            </div>
            <Tabs
              view={view}
              setView={setView}
              className=""
              assetsLen={assets.length}
              activitiesLen={activities?.length}
              collectionsLen={collections
                .map((collection) => collection.udas.length)
                .reduce((a, b) => a + b, 0)}
            />
          </div>
          <Suspense
            fallback={<Loader className="w-12 h-12 m-auto md:h-20 md:w-20" />}
          >
            {view === 0 && (
              <Activities
                activities={activities}
                loadingActivities={loadingActivities}
                walletData={walletData}
                assetUtxos={assetUtxos}
                vault={vault}
                lnCredentials={lnCredentials}
                hash={hash}
                conversion={conversion}
                network={network}
              />
            )}
            {view === 1 && (
              <Assets
                assets={assets}
                loadingAssets={loadingAssets}
                loadingVault={loadingVault}
                pendingAssetTransfers={pendingAssetTransfers}
                walletData={walletData}
                assetUtxos={assetUtxos}
                vault={vault}
                hash={hash}
                lnCredentials={lnCredentials}
                tokensWalletFunded={tokensWalletFunded}
                tokensWalletAddress={tokensWalletAddress}
                udasWalletAddress={udasWalletAddress}
              />
            )}
            {view === 2 && (
              <Collections
                collections={collections}
                loadingUda={loadingUda}
                walletData={walletData}
                assetUtxos={assetUtxos}
                pendingUdaTransfers={pendingUdaTransfers}
                lnCredentials={lnCredentials}
                vault={vault}
                hash={hash}
              />
            )}
          </Suspense>
        </div>
      </div>
      {view !== 0 && (
        <div className="w-full mx-auto 4xl:w-4/12 3xl:w-5/12 2xl:w-6/12 xl:w-7/12 lg:w-8/12">
          <AssetFooter
            vault={vault}
            walletData={walletData}
            assetUtxos={assetUtxos}
            hash={hash}
            lnCredentials={lnCredentials}
            setOpenFundAssetsModal={setOpenFundAssetsModal}
            tokensWalletFunded={tokensWalletFunded}
            loadingVault={loadingVault}
            view={view}
            tokensWalletAddress={tokensWalletAddress}
            udasWalletAddress={udasWalletAddress}
            fundingPending={fundingPending}
            invoices={invoices}
            transfers={transfers}
            backend={backend}
          />
        </div>
      )}
      <Modal open={openFundAssetsModal} setOpen={setOpenFundAssetsModal}>
        <FundAssets
          walletBalance={addBalance(walletData?.balance)}
          tokensWalletAddress={tokensWalletAddress}
          udasWalletAddress={udasWalletAddress}
          vault={vault}
          walletData={walletData}
          wallet={wallet}
          lnCredentials={lnCredentials}
          hash={hash}
          network={network}
          setOpenFundAssetsModal={setOpenFundAssetsModal}
        />
      </Modal>
      <Modal open={openVersionPopup} setOpen={setOpenVersionPopup}>
        <ReleaseNotes version={VERSION} releaseNotes={releaseNotes} />
      </Modal>
      <CopiedToClipboard open={openCopyModal} setOpen={setOpenCopyModal} />
      <ErrorModal
        open={openError}
        setOpen={setOpenError}
        message={displayedError.message}
        title={displayedError.title}
      />
    </div>
  );
};

export default Wallet;
